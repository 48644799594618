
$gold: #5135FE;
$royal: #d4af37;

$alice-green: #EDF5FC;
$blue: #5135FE;        // Medium Sea blue
//$blue: #58BC82;        // Medium Sea blue
$orange: #F85E00;       // Orange pantone
$red: #D00000;           // Rosso Corsa
$green: #58BC82;         // Cyan Process
//$green: #5BC0EB;         // Cyan Process
$blue: #5135FE;

$gray-x-light: #f5f5f5;
$gray-light: #C2C2C2;
$gray-dark: #69747C;    // Sonic silver
$rich-dark: #272D2D;
$lavender-web: #f6f6f6; // Gainsboro
$slategray: #708090;

.text-color-gold        {   color: $gold !important;    }
.text-color-rich-dark   {   color: $rich-dark !important;    }
.text-color-red         {   color: red !important;    }
.bg-color-alice-green    {   background-color: $alice-green !important;   }
.bg-color-gold          {   background-color: $gold !important;    }
.bg-color-gold-wa       {   background-color: rgba($color: $gold, $alpha: 0.25)  !important;    }
.bg-color-rich-dark     {   background-color: $rich-dark !important;    }
.bg-color-lavender      {   background-color: $lavender-web !important;    }
.bg-color-gray-light    {   background-color: $gray-light !important;    }
.bg-color-gray-dark     {   background-color: rgba($color: #4d4d4d, $alpha: .75)}
.bg-color-blue         {   background-color: $blue !important;    }
.bg-color-blue-wa      {   background-color: rgba($color: $blue, $alpha: 0.1) !important;    }
.bg-color-orange        {   background-color: orange !important;    }
.bg-color-orange-wa     {   background-color: rgba($color: orange, $alpha: 0.1)  !important;    }
.bg-color-red           {   background-color: red !important;    }
.bg-color-green          {   background-color: $green !important;    }
.bg-color-green-wa       {   background-color: rgba($color: $green, $alpha: 0.1)  !important;    }
.bg-color-black         {   background-color: black; }
.bg-color-white         {   background-color: white; }
.color-white            {   color: white; }
.color-error            {   color: red !important;  }