/* Markdown Base Styling */
.markdown-container {
    font-family: Arial, sans-serif;
    line-height: 1.5;
    color: #333; /* General text color */
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Headings */
.markdown-container h1, 
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
    color: #5135FE;
    padding-top: 10px;
}

.markdown-container h1 {
    border-bottom: 2px solid #5135FE;
}

/* Links */
.markdown-container a {
    text-decoration: none;
    color: #5135FE;
    transition: color 0.3s;
}

.markdown-container a:hover {
    color: #4124CE; /* Slightly darker for hover effect */
}

/* Lists */
.markdown-container ul,
.markdown-container ol {
    margin-left: 20px;
    margin-top: 6px;
    color: #444;
}

/* Code */
.markdown-container code {
    font-family: "Courier New", monospace;
    background-color: rgba(81, 53, 254, 0.1); /* Light background with primary color */
    padding: 2px 6px;
    border-radius: 4px;
}

.markdown-container pre {
    background-color: rgba(81, 53, 254, 0.1);
    padding: 10px;
    border-radius: 4px;
    overflow: auto;
}

/* Blockquotes */
.markdown-container blockquote {
    border-left: 4px solid #5135FE;
    padding-left: 15px;
    color: #666;
    font-style: italic;
}

/* Images */
.markdown-container img {
    max-width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Horizontal Rule */
.markdown-container hr {
    border: none;
    height: 1px;
    background: rgba(81, 53, 254, 0.2);
    margin: 20px 0;
}

/* Tables */
.markdown-container table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.markdown-container th,
.markdown-container td {
    border: 1px solid rgba(81, 53, 254, 0.2);
    padding: 8px 12px;
    text-align: left;
}

.markdown-container th {
    background-color: rgba(81, 53, 254, 0.1);
    color: #5135FE;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .markdown-container {
        padding: 10px;
    }
}
