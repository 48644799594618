
@font-face
{
    font-family: 'ArefRuqaa-Regular';
    src: url('../../assets/fonts/ArefRuqaa-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/ArefRuqaa-Regular.woff') format('woff'),
         url('../../assets/fonts/ArefRuqaa-Regular.woff2') format('woff2'),
         url('../../assets/fonts/ArefRuqaa-Regular.ttf')  format('truetype'),
         url('../../assets/fonts/ArefRuqaa-Regular.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'ArefRuqaa-Bold';
    src: url('../../assets/fonts/ArefRuqaa-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/ArefRuqaa-Bold.woff') format('woff'),
         url('../../assets/fonts/ArefRuqaa-Bold.woff2') format('woff2'),
         url('../../assets/fonts/ArefRuqaa-Bold.ttf')  format('truetype'),
         url('../../assets/fonts/ArefRuqaa-Bold.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Lato-Black';
    src: url('../../assets/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Lato-Black.woff') format('woff'),
         url('../../assets/fonts/Lato-Black.woff2') format('woff2'),
         url('../../assets/fonts/Lato-Black.ttf')  format('truetype'),
         url('../../assets/fonts/Lato-Black.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Lato-BlackItalic';
    src: url('../../assets/fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Lato-BlackItalic.woff') format('woff'),
         url('../../assets/fonts/Lato-BlackItalic.woff2') format('woff2'),
         url('../../assets/fonts/Lato-BlackItalic.ttf')  format('truetype'),
         url('../../assets/fonts/Lato-BlackItalic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Lato-Bold';
    src: url('../../assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Lato-Bold.woff') format('woff'),
         url('../../assets/fonts/Lato-Bold.woff2') format('woff2'),
         url('../../assets/fonts/Lato-Bold.ttf')  format('truetype'),
         url('../../assets/fonts/Lato-Bold.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Lato-BoldItalic';
    src: url('../../assets/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Lato-BoldItalic.woff') format('woff'),
         url('../../assets/fonts/Lato-BoldItalic.woff2') format('woff2'),
         url('../../assets/fonts/Lato-BoldItalic.ttf')  format('truetype'),
         url('../../assets/fonts/Lato-BoldItalic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Lato-Italic';
    src: url('../../assets/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Lato-Italic.woff') format('woff'),
         url('../../assets/fonts/Lato-Italic.woff2') format('woff2'),
         url('../../assets/fonts/Lato-Italic.ttf')  format('truetype'),
         url('../../assets/fonts/Lato-Italic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Lato-Light';
    src: url('../../assets/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Lato-Light.woff') format('woff'),
         url('../../assets/fonts/Lato-Light.woff2') format('woff2'),
         url('../../assets/fonts/Lato-Light.ttf')  format('truetype'),
         url('../../assets/fonts/Lato-Light.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Lato-LightItalic';
    src: url('../../assets/fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Lato-LightItalic.woff') format('woff'),
         url('../../assets/fonts/Lato-LightItalic.woff2') format('woff2'),
         url('../../assets/fonts/Lato-LightItalic.ttf')  format('truetype'),
         url('../../assets/fonts/Lato-LightItalic.svg#svgFontName') format('svg');
}
@font-face
{
    font-family: 'Lato-Regular';
    src: url('../../assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/Lato-Regular.woff') format('woff'),
         url('../../assets/fonts/Lato-Regular.woff2') format('woff2'),
         url('../../assets/fonts/Lato-Regular.ttf')  format('truetype'),
         url('../../assets/fonts/Lato-Regular.svg#svgFontName') format('svg');
}

h1
{
    font-size: 2.2rem;
}
h2
{
    font-size: 1.5rem;
}

.typo-header-title
{
    text-transform: uppercase;
    font-family: 'Lato-Regular';
    font-size: 1.1rem;
    line-height: 1.1rem;
    color: black;
    font-weight: 500;
}

.typo-brand
{
    text-transform: uppercase;
    font-family: 'Lato-Bold';
    font-size: 1.15rem;
    line-height: 1.15rem;
    font-weight: bold;
    color: black;
}

.typo-title
{
    font-family: 'Lato-Regular';
    font-size: .7rem;
    line-height: 1rem;
    color: black;
}

.typo-price
{
    font-family: 'Lato-Bold';
    font-size: 1.15rem;
    font-weight: bold;
    line-height: 1.25rem;
    color: black;
}
.typo-price-2
{
    font-family: 'Lato-Regular';
    font-size: 1.15rem;
    line-height: 1.25rem;
    color: black;
}

.typo-currency
{
    font-family: 'Lato-Bold';
    vertical-align: super;
    font-size: 0.8em;
    color: black; 
}

.typo-subtitle
{
    font-family: 'Lato-Bold';
    font-size: .9rem;
    line-height: .9rem;
    color: black;
    text-transform: uppercase;
}

.typo-youtube-description
{
    font-family: 'Lato-Italic';
    font-size: .65rem;
    line-height: 1rem;
    color: black;
}

.typo-disclaimer
{
    font-family: 'Lato-Italic';
    font-size: .65rem;
    line-height: .65rem;
    color: $gray-dark;
}
.typo-disclaimer-link
{
    font-family: 'Lato-Regular';
    font-size: .7rem;
    line-height: .7rem;
}

.typo-footer-bold
{
    font-family: 'Lato-Bold';
    font-size: 1.1rem;
    line-height: 1.1rem;
    color: black;
}

.typo-sort
{
    font-family: 'Lato-Regular';
    font-size: .1rem;
    color: black;
}

.typo-description
{
    font-family: 'Lato-Regular';
    font-size: .1rem;
    line-height: .1rem;
    color: black;
}

.typo-chip
{
    font-family: 'Lato-Regular';
    font-size: .75rem;
    line-height: .6rem;
    color: black;
}

.typo-lowest-ever
{
    font-family: 'Lato-Regular';
    font-size: .8rem;
    line-height: .8rem;
    color: white;
}

.typo-chip-follow
{
    text-transform: uppercase;
    font-family: 'Lato-Regular';
    font-size: .9rem;
    line-height: .9rem;
    color: black;
}

.typo-breadcrumb
{
    font-family: 'Lato-Regular';
    font-size: .9rem;
    line-height: .9rem;
    color: white;
}

.typo-regular-white
{
    font-family: 'Lato-Regular';
    font-size: .9rem;
    line-height: .9rem;
    color: white;
}
.typo-regular-black
{
    font-family: 'Lato-Regular';
    font-size: .9rem;
    line-height: .9rem;
    color: black;
}
.typo-regular-royal
{
    font-family: 'Lato-Regular';
    font-size: .9rem;
    line-height: .9rem;
    color: #5135FE;
}
.typo-bold-black
{
    font-family: 'Lato-Bold';
    font-size: .9rem;
    line-height: .9rem;
    color: black;
}
.typo-bold-white
{
    font-family: 'Lato-Bold';
    font-size: .9rem;
    line-height: .9rem;
    color: white;
}
.typo-marketplace
{
    font-family: 'Lato-Bold';
    font-size: 1.15rem;
    font-weight: bold;
    line-height: 1.15rem;
    color: black;
}
.typo-flag
{
    text-transform: uppercase;
    font-family: 'Lato-Bold';
    font-size: .9rem;
    line-height: .9rem;
    color: white;
    white-space: pre-line;
}
.typo-excess
{
    font-family: 'Lato-Bold';
    font-size: .7rem;
    line-height: .9rem;
    color: orange;
    white-space: nowrap;
}
.typo-excess-small
{
    font-family: 'Lato-Bold';
    font-size: .6rem;
    line-height: .65rem;
    color: orange;
    white-space: nowrap;
}
.typo-see-all
{
    font-family: 'Lato-Bold';
    font-size: .9rem;
    font-weight: bold;
    line-height: .9rem;
    color: $gold;
    text-decoration: underline;
}

@media (max-width: 1280px)
{
    .typo-chip
    {
        font-size: .75rem;
        line-height: .6rem;
    }
}

@media (max-width: 768px)
{
    .typo-brand
    {
        font-size: .9rem;
        line-height: .9rem;
    }
    .typo-price
    {
        font-size: .9rem;
        line-height: .9rem;
    }
    .typo-regular-white
    {
        font-size: .8rem;
        line-height: .8rem;
    }
    .typo-regular-black
    {
        font-size: .8rem;
        line-height: .8rem;
    }
    .typo-regular-royal
    {
        font-size: .8rem;
        line-height: .8rem;
    }
    .typo-marketplace
    {
        font-size: .9rem;
        line-height: .9rem;
    }
    .typo-bold-black
    {
        font-size: .8rem;
        line-height: .8rem;
    }
    .typo-bold-white
    {
        font-size: .8rem;
        line-height: .8rem;
    }
    .typo-chip
    {
        font-size: .75rem;
        line-height: .6rem;
    }
    .typo-chip-follow
    {
        font-size: .7rem;
        line-height: .7rem;
    }
    .typo-sort
    { 
        font-size: .7rem;
    }
    .typo-flag
    {
        font-size: .7rem;
        line-height: .7rem;
    }
}