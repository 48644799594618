
.modal
{
    display: none;
    background-color: rgba($color: #000000, $alpha: .5);
    z-index: 999;
}

.modal.active
{
    display: flex;
}

.mod-header
{
    height: 2.5rem;
}

.mod-body
{
    max-width: 90vw;
    max-height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.mod-dialog
{
    background-color: $lavender-web;
    border-radius: .5rem;

    overflow: hidden;
}

.mod-close
{
    width: 1.5rem;
    height: 1.5rem;
}