@import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../../node_modules/@angular/cdk/overlay-prebuilt.css';
@import '../../../node_modules/ngx-sharebuttons/themes/default.scss';

@import "colors.scss";
@import "typos.scss";
@import "modal.scss";
@import "markdown.scss";

body {
    font-family: 'Lato-Regular' !important;
    font-size: 1rem !important;
    color: black !important;
    background-color: $lavender-web !important;
}

body.dark-mode {
    background-color: black;
    color: white;
    /* Add other dark mode styles as needed */
}

hr {
    opacity: .08 !important;
}

::-webkit-scrollbar {
    height: .2rem;
    width: .4rem;
}

::-webkit-scrollbar-track {
    background: $rich-dark;
}

::-webkit-scrollbar-thumb {
    background: $gold;
}

::-webkit-scrollbar-thumb:hover {
    background: white;
}

input[type=checkbox] {
    transform: scale(1.2);
}

.spinner {
    transform: translate(-2rem, 0);
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.border-around {
    border: 1px solid $gray-light;
    border-radius: .6rem;
    background-color: white;
}

.border-radius-std {
    border-radius: .2rem;
}

.separator {
    background-color: $gray-light;
    height: 1px;
}

.border-light-gray {
    border: 1px solid $gray-light;
}

.input-invalid {
    border-bottom: 1px solid red !important;
}

.bg-img {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.btn-track {
    cursor: pointer;
}

// Icons
.logo-size {
    width: 2.5rem;
    height: 2.5rem;
}

.picto-xl {
    width: 2.5rem;
}

.picto-big {
    width: 2rem;
}

.picto-medium {
    width: 1.5rem;
}

.picto-small {
    width: 1rem;
}

.picto-x-small {
    width: .75rem;
}

.picto-xx-small {
    width: .5rem;
}

// Others
.cursor-pointer {
    cursor: pointer;
}

.pre-line {
    white-space: pre-line;
}

.circle {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.button-square {
    width: 2rem;
    height: 2rem;
    border-radius: .2rem;
    cursor: pointer;
}

.sub-item {
    padding-left: 20px;
    /* If you added the padding for indentation */
    font-size: 90%;
    /* This will decrease the font size to 90% of its inherited value */
}

@media (max-width: 768px) {
    .logo-size {
        width: 2.25rem;
        height: 2.25rem;
    }

    .picto-xl {
        width: 2.25rem;
    }

    .picto-big {
        width: 1.75rem;
    }

    .picto-medium {
        width: 1.25rem;
    }

    .picto-small {
        width: .75rem;
    }

    .picto-sq-small {
        width: .75rem !important;
        height: .75rem !important;
    }
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
    body {
        background-color: black;
        color: white;
    }
}